<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Email Setting : {{ Product }}</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <table class="table" v-if="details">
            <tbody>
                <tr v-for="(v, k, index) in details" :key="index" v-if="k !== 'id' && k !== 'products' && k !== 'emails' ">
                    <th>{{ k|capitalize }}</th>
                    <td colspan="2" v-if="k === 'product'">{{ Product }}</td>
                  <td colspan="2" v-else v-html="v">{{ v }}</td>
                </tr>
                <tr>
                  <th colspan="3">&nbsp;</th>
                </tr>
                <tr>
                  <th colspan="3">Emails Addresses</th>
                </tr>
                <tr>
                  <th class="text-muted">
                    Name
                  </th>
                  <th class="text-muted">
                    Email
                  </th>
                  <th class="text-muted">
                    CC email?
                  </th>
                </tr>
                <tr v-for="email in details.emails" :key="100 - parseInt(email.id) " >
                  <td>{{email.name}}</td>
                  <td>{{email.email}}</td>
                  <td>{{email.cc}}</td>
                </tr>
            </tbody>

          </table>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button v-show="hasFullAccess" :to="{ name: 'SCEmailSettingEdit', params: { id: this.$route.params.id } }" variant="outline-dark" class="float-right">Edit</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import base64url from "base64url";

  export default {
    name: 'EmailSetting',
    components: {Id},
    props: {
      caption: {
        type: String,
        default: 'Email Setting Details'
      },
    },
    data: () => {
      return {
        details: {
         from_email: "",
         to_email: "",
         product: "",
         products: [],
         emails: []
        },
        options: {
          products: []
        }
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getEmailSetting() {

       let url = '';

        url = '/email-settings/' + this.$route.params.id +  '/?product=' + this.$route.params.product;

        axios.get(url).then(
          response => {
            this.details = response.data
            this.options.products = response.data.products
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('email setting error');
            console.log(error.response)
          })
        return this.details
      }
    },
    computed: {
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
      Product() {
        let product_text = ''

        if (this.details) {

          this.details.products.filter(item => {

              if (item.value === this.details.product) {
                product_text = item.text
              }
            })
        }

        return product_text
      }
    },
    mounted() {
      this.getEmailSetting()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getEmailSetting()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
